import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import Banner from '../components/Banner'
import Button from '../components/Button'

import * as S from "../components/Service/styled"

const Service = ({ data }) => {
  const service = data.markdownRemark
  const images = service.frontmatter.images;
  const banner = data.banner
  const image1 = data.image1
  const image2 = data.image2
  const image3 = data.image3
  const exaContact = '/fale-conosco';
  let lstImages = [];

  // console.log(`Images => ${service.frontmatter.images}`);
  if (images >= 1) {
    lstImages.push(image1.childImageSharp.fluid);
  }
  if (images >= 2) {
    lstImages.push(image2.childImageSharp.fluid);
  }
  if (images >= 3) {
    lstImages.push(image3.childImageSharp.fluid);
  }

  return (
    <Layout>
      <SEO title={service.frontmatter.title} description={`Exame - ${service.frontmatter.title}`} />
      <Banner images={banner}/>
      <S.Container className="pt-4 pb-4">
        <S.ContainerText>
          <h3>{service.frontmatter.title}</h3>
          <S.DivText dangerouslySetInnerHTML={{ __html: service.html }}></S.DivText>
          <S.DivImages>
            {lstImages.map((item,i) => {
              return (
                <S.DivImage key={i}>
                  <S.Image fluid={item} />
                </S.DivImage>
              )
            })}
          </S.DivImages>
          <Button to={exaContact}>Agende já</Button>
        </S.ContainerText>
      </S.Container>
      </Layout>
  )
}

export const query = graphql`
  query Service($slug: String!,$image: String!,$image1: String!,$image2: String!,$image3: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        images
      }
      html
    },
    banner: file(relativePath: {eq: $image}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image1: file(relativePath: {eq: $image1}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: {eq: $image2}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(relativePath: {eq: $image3}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Service