import styled from 'styled-components'
import media from 'styled-media-query'
import { Container as bContainer } from 'reactstrap'
import Img from 'gatsby-image';

export const Container = styled(bContainer)`
  /* margin: 30px 0;
  margin-bottom: 60px; */
`

export const ContainerText = styled.div`
/* background: linear-gradient(to bottom,#f9fbfd 0,#fff 100%); */

  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  align-items: center;

  /* h2 {
    max-width: 60%;
    min-width: 300px;
    ${media.lessThan('small')`
      font-size: 25px;
    `}
  } */

  p {
    margin-top: 15px;
    max-width: 50%;
    min-width: 300px;
    font-size: 15px;
  }

  a {
    color: #6c757d !important;
  }
`

export const DivText = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
`

export const DivImages = styled.div`
  display: flex;
  text-align: center;
  margin-bottom: 2rem;
  justify-content: space-evenly;
  width: 70%;
`

export const DivImage = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  width: 150px;
  height: 150px;
`

export const Image = styled(Img)`
  width: 200px;
  height: auto;
`